import React from "react"
import {
  componentResolverFromMap,
  withPrismicUnpublishedPreview
} from "gatsby-plugin-prismic-previews"
import { Link, PageProps } from "gatsby"
import { Page } from "../components/page"
import { linkResolver } from "../utilities/linkResolver"

const NotFound = (props: PageProps) => (
  <div className="fixed inset-0 h-screen w-screen flex items-center justify-center">
    <h1 className="font-serif font-bold text-5xl">
      Page not found.<br /><Link className="text-red italic" to="/">Go home</Link>.
    </h1>
  </div>
)

export default withPrismicUnpublishedPreview(NotFound/*, [{
  repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY ?? ``,
  linkResolver,
  componentResolver: componentResolverFromMap({
    page: Page,
  }),
}]*/)
